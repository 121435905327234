import { trackCtaClick } from '@/lib/gtm/gtmV2'
import styled from 'styled-components'

const StyledPlatformLogos = styled.ul`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  align-items: center;
  gap: 50px 20px;
  list-style: none;
  padding: 0;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    gap: 50px 0;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    grid-template-columns: 1fr;
  }
`

type PodcastPlatform = 'Apple' | 'Google' | 'YouTube' | 'Spotify' | 'Amazon'

const trackPodcastPlatformClick = (platform: PodcastPlatform) => {
  trackCtaClick('podcast', platform)
}

export const ListenOnLogos = () => {
  return (
    <StyledPlatformLogos>
      <li>
        <a
          href="https://open.spotify.com/show/1xDawqT8Cgm6HAnbpFX5T5?si=gMKxn5qWTq6MFEz4doL46A"
          target="_blank"
          onClick={() => trackPodcastPlatformClick('Spotify')}
        >
          <img src="/assets/img/logos/spotify.svg" alt="Spotify" width="143" height="43" />
        </a>
      </li>
      <li>
        <a
          href="https://podcasts.apple.com/gb/podcast/zoe-science-nutrition/id1611216298"
          target="_blank"
          onClick={() => trackPodcastPlatformClick('Apple')}
        >
          <img src="/assets/img/logos/apple-podcasts.svg" alt="Apple Podcasts" width="197" height="33" />
        </a>
      </li>
      <li>
        <a
          href="https://youtube.com/@joinzoe?si=1bDeMw5VCyHkXRPxsub_confirmation=1"
          target="_blank"
          onClick={() => trackPodcastPlatformClick('YouTube')}
        >
          <img src="/assets/img/logos/youtube.svg" alt="YouTube" width="134" height="30" />
        </a>
      </li>
      <li>
        <a
          href="https://music.amazon.co.uk/podcasts/c3d23565-3f36-47fc-97d6-044a251b57bf/zoe-science-nutrition?ref=dm_sh_BYjXqPztz2XLMQrndk7Mye0Ck"
          target="_blank"
          onClick={() => trackPodcastPlatformClick('Amazon')}
        >
          <img src="/assets/img/logos/amazon-music.svg" alt="Amazon music" width="134" height="30" />
        </a>
      </li>
    </StyledPlatformLogos>
  )
}
