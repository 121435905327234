import React from 'react'
import { StructuredText } from 'react-datocms'
import { ExpandableSectionRecord } from 'types'
import { Table } from './Table'
import { ExpandableSection } from '../shared/ExpandableSection'

export type ExpandableSectionBlockProps = {
  id: string
  open?: boolean
  onToggle: () => void
  record: ExpandableSectionRecord
}

const renderBlock = (record) => {
  switch (record.__typename) {
    case 'TableRecord':
      return <Table record={record} />
    default:
      return null
  }
}

export const ExpandableSectionBlock = ({ onToggle, open, record }: ExpandableSectionBlockProps) => (
  <ExpandableSection id={record.sectionAnchorId} title={record.title} open={open} onToggle={onToggle}>
    <StructuredText data={record.content} renderBlock={({ record }) => renderBlock(record)} />
  </ExpandableSection>
)
