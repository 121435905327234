import { AlternateGridRowRecord } from 'types'
import styled from 'styled-components'
import { DatoImage } from '@/elements/global/DatoImage'
import { StructuredText } from 'react-datocms'

const StyledRow = styled.section<{ $rowAlt: boolean }>`
  display: flex;
  align-items: center;
  ${(p) => p.$rowAlt !== true && `flex-direction: row-reverse;`}

  @media screen and (max-width: ${(p) => p.theme.breakpoints.mobile}) {
    flex-direction: column;
  }
`
const StyledDesktopImage = styled.section`
  width: 50%;
  padding-top: 50%;
  position: relative;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.mobile}) {
    display: none;
  }
`

const StyledMobileImage = styled.section`
  width: 100%;
  padding-top: 66.72%;
  position: relative;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.mobile}) {
    display: none;
  }
`

const StyledText = styled.section<{ $rowAlt: boolean }>`
  &.styled-text {
    text-align: left;
    flex: 1;
    padding: 0 64px;
    margin: 0;

    h2 {
      font-size: ${(p) => p.theme.v2.typography.body.xl.fontSize};
      line-height: ${(p) => p.theme.v2.typography.body.xl.lineHeight};
      font-family: ${(p) => p.theme.v2.font.nantes};
      color: ${(props) => props.theme.v2.onSurface.text};
      margin-top: 0;
      text-align: left;
    }
    h3 {
      font-size: ${(p) => p.theme.v2.typography.body.xl.fontSize};
      line-height: ${(p) => p.theme.v2.typography.body.xl.lineHeight};
      font-family: ${(p) => p.theme.v2.font.nantes};
      color: ${(props) => props.theme.v2.onSurface.text};
      padding: 0;
      margin-top: 0;
      text-align: left;
      @media screen and (max-width: ${(p) => p.theme.breakpoints.mobile}) {
        text-align: center;
      }
    }

    p {
      font-family: ${(p) => p.theme.v2.font.lotaGrotesqueLight};
      color: ${(props) => props.theme.v2.onSurface.subduedText};
      font-size: ${(p) => p.theme.v2.typography.body.xs.fontSize};
      line-height: ${(p) => p.theme.v2.typography.body.xs.lineHeight};
      text-align: left;
      margin-top: 0;
      @media screen and (max-width: ${(p) => p.theme.breakpoints.mobile}) {
        text-align: center;
      }
    }

    @media screen and (max-width: ${(p) => p.theme.breakpoints.mobile}) {
      text-align: center;
      padding: 32px 40px 64px 40px;
      ${(p) => p.$rowAlt && `background-color: ${p.theme.v2.surface.cream500};`}
      ${(p) => !p.$rowAlt && `background-color: ${p.theme.v2.surface.white};`}
    }
  }
`

type AlternateGridRowProps = {
  record: AlternateGridRowRecord
  rowAlt: boolean
}

export const AlternateGridRow: React.FC<AlternateGridRowProps> = ({ record, rowAlt }) => {
  return (
    <StyledRow $rowAlt={rowAlt}>
      <StyledDesktopImage>
        <DatoImage data={record.image.responsiveImage} layout="fill" objectFit="cover" />
      </StyledDesktopImage>

      <StyledMobileImage>
        <DatoImage data={record.mobileImage.responsiveImage} layout="fill" objectFit="cover" />
      </StyledMobileImage>

      <StyledText className="styled-text" $rowAlt={rowAlt}>
        <StructuredText data={record.content} />
      </StyledText>
    </StyledRow>
  )
}
