import styled from 'styled-components'
import { SciencePageLatestArticleRecord } from 'types'
import { StructuredText } from 'react-datocms'

const StyledLatestArticleBlock = styled.div`
  &.science-latest-article {
    background-color: ${(props) => props.theme.v2.surface.cream500};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 16px;
    padding: 30px;
    min-width: 270px;
    max-width: 400px;

    img {
      height: 21px;
    }

    h4 {
      font-size: ${(p) => p.theme.v2.typography.label.m.fontSize};
      line-height: ${(p) => p.theme.v2.typography.label.m.lineHeight};
      font-family: ${(p) => p.theme.v2.font.lotaGrotesqueLight};
      color: ${(props) => props.theme.v2.onSurface.text};
      text-align: left;
    }

    h3 {
      font-size: ${(p) => p.theme.v2.typography.body.s.fontSize};
      line-height: ${(p) => p.theme.v2.typography.body.s.lineHeight};
      font-family: ${(p) => p.theme.v2.font.lotaGrotesqueRegular};
      color: ${(props) => props.theme.v2.onSurface.text};
      text-align: left;
      margin-top: 30px;
      margin-bottom: 30px;
    }

    p {
      font-size: ${(p) => p.theme.v2.typography.body.xs.fontSize};
      line-height: ${(p) => p.theme.v2.typography.body.xs.lineHeight};
      font-family: ${(p) => p.theme.v2.font.lotaGrotesqueLight};
      color: ${(props) => props.theme.v2.onSurface.text};
      text-align: left;
    }

    .top {
      margin-bottom: 30px;
    }
  }
`

type SciencePageLatestArticleBlockProps = {
  record: SciencePageLatestArticleRecord
}

export const SciencePageLatestArticleBlock: React.FC<SciencePageLatestArticleBlockProps> = ({ record }) => {
  return (
    <StyledLatestArticleBlock className="science-latest-article">
      <div className="top">
        <img src={record.journalLogo.url} alt={`Science latest article from ${record.date}`} height="25" />

        <h4>{record.date}</h4>

        <StructuredText data={record.content} />
      </div>

      <div>
        <a href={record.link} target="_blank" rel="noreferrer">
          Read more
        </a>
      </div>
    </StyledLatestArticleBlock>
  )
}
