import { ButtonRecord } from 'types'
import { Button } from '@/components/shared/Button'
import { trackCtaClick } from '@/lib/gtm/gtmV2'

type ButtonBlockProps = {
  record: ButtonRecord
  withArrow?: boolean
}

const trackButtonClick = (btnName: string) => {
  trackCtaClick('cms', btnName)
}

export const ButtonBlock: React.FC<ButtonBlockProps> = ({ record, withArrow = false }) => {
  return (
    <Button
      key={record.id}
      url={record.link}
      text={record.label}
      style={record.buttonType}
      onClick={() => trackButtonClick(record.customTracking ? record.customTracking : record.label)}
      withArrow={withArrow}
    />
  )
}
