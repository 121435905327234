import styled from 'styled-components'

const StyledJournalsLogos = styled.ul`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  align-items: center;
  gap: 80px 40px;
  list-style: none;
  padding: 0;
  margin: 32px 0 8px 0;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    grid-template-columns: 1fr 1fr;
    gap: 42px;
    margin-top: 34px;
    margin-bottom: 2px;
  }

  img {
    height: 32px;

    @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      height: 28px;
    }
  }
`

export const JournalsLogos = () => {
  return (
    <StyledJournalsLogos>
      <li>
        <a href="https://www.nature.com/articles/s41591-020-01183-8" target="_blank" rel="noreferrer">
          <img src="/assets/img/logos/NatureLogo.svg" alt="Nature Journal" width="238" height="28" />
        </a>
      </li>
      <li>
        <a href="https://www.science.org/doi/10.1126/science.abc0473" target="_blank" rel="noreferrer">
          <img src="/assets/img/logos/ScienceLogo.svg" alt="Science Journal" width="146" height="40" />
        </a>
      </li>
      <li>
        <a
          href="https://www.thelancet.com/journals/lancet/article/PIIS0140-6736(22)00327-0/fulltext"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/assets/img/logos/LancetLogo.svg" alt="Lancet Journal" width="271" height="29" />
        </a>
      </li>
      <li>
        <a href="https://gut.bmj.com/content/70/9/1665" target="_blank" rel="noreferrer">
          <img src="/assets/img/logos/BMJGutLogo.svg" alt="BMJ Gut Journal" width="124" height="49" />
        </a>
      </li>
    </StyledJournalsLogos>
  )
}
