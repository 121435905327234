import { SciencePageScientistBlock } from '@/components/blocks/SciencePageScientistBlock'
import { StandardButton } from '@/components/shared/StandardButton'
import { useState } from 'react'
import styled from 'styled-components'
import { SciencePageScientistsGroupRecord } from 'types'

const StyledSciencePageScientistsGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    margin-top: 30px;
  }
`

const StyledScientistsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 48px;
  margin-bottom: 24px;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.mobile}) {
    grid-template-columns: 1fr;
  }
`

type SciencePageScientistsGroupBlockProps = {
  record: SciencePageScientistsGroupRecord
}

export const SciencePageScientistsGroupBlock: React.FC<SciencePageScientistsGroupBlockProps> = ({ record }) => {
  const [viewCount, setViewCount] = useState(9)
  const showViewMoreBtn = viewCount !== record.scientists.length
  const handleViewMoreButton = () => setViewCount(record.scientists.length)

  return (
    <StyledSciencePageScientistsGroup>
      <StyledScientistsGrid>
        {record.scientists.slice(0, viewCount).map((scientist) => (
          <SciencePageScientistBlock record={scientist} key={scientist.id} />
        ))}
      </StyledScientistsGrid>

      {showViewMoreBtn && (
        <StandardButton btnStyle="primary" onClick={handleViewMoreButton}>
          Meet the rest of the team
        </StandardButton>
      )}
    </StyledSciencePageScientistsGroup>
  )
}
