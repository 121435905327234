import { Button } from '@/components/shared/Button'
import { trackCtaClick } from '@/lib/gtm/gtmV2'
import React from 'react'
import { StructuredText } from 'react-datocms'
import styled from 'styled-components'
import { HomePageFreeGuideBlockRecord } from 'types'

type HomePageFreeGuideBlockProps = {
  record: HomePageFreeGuideBlockRecord
}

const StyledCentredContentBlock = styled.div`
  padding: 0;
  color: ${(props) => props.theme.v2.surface.dark};
  display: flex;
  flex-direction: row;
  gap: 160px;
  margin: 0 auto;

  .free-guide-container-left h2 {
    font-size: ${(props) => props.theme.v2.typography.display.xs.fontSize};
    line-height: ${(props) => props.theme.v2.typography.display.xs.lineHeight};
    font-family: ${(props) => props.theme.v2.font.nantes};
    text-align: left;

    @media screen and (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      font-size: ${(props) => props.theme.v2.typography.body.xl.fontSize};
      line-height: ${(props) => props.theme.v2.typography.body.xl.lineHeight};
      text-align: center;
    }
  }

  .free-guide-container-left h6 {
    font-family: ${(props) => props.theme.v2.font.lotaGrotesqueLight};
    font-size: ${(props) => props.theme.v2.typography.body.xxs.fontSize};
    line-height: ${(props) => props.theme.v2.typography.body.xxs.lineHeight};
    margin-block-start: 16px;
    margin-block-end: 16px;

    @media screen and (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      text-align: center;
    }
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.largeDesktop}) {
    gap: 80px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    gap: 0;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    gap: 0;
    flex-direction: column-reverse;
  }
`

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 350px;

  span {
    font-family: ${(props) => props.theme.v2.font.lotaGrotesqueLight};
    font-size: ${(props) => props.theme.v2.typography.body.xs.fontSize};
    line-height: ${(props) => props.theme.v2.typography.body.xs.lineHeight};
    text-transform: uppercase;

    @media screen and (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      text-align: center;
      margin-top: 32px;
      margin-bottom: 11px;
    }
  }

  a.button {
    margin-top: 0px;
  }
`

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  min-width: 170px;

  img {
    border-radius: 16px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    align-items: center;
    img {
      max-width: 144px;
    }
  }
`

const onClickHandler = (label: string) => {
  trackCtaClick('homepage', label)
}

export const HomePageFreeGuideBlock: React.FC<HomePageFreeGuideBlockProps> = ({ record }) => {
  return (
    <StyledCentredContentBlock className="free-guide-container">
      <LeftContainer className="free-guide-container-left">
        <span>Free Download</span>
        <StructuredText data={record.header} />
        <StructuredText data={record.subheader} />

        <Button
          key={record.id}
          url="/gutguide"
          text={record.cta}
          style="primary"
          onClick={() => onClickHandler(record.cta)}
        />
      </LeftContainer>
      <RightContainer>
        <img src="/assets/img/gutshot/gut-health.png" alt="Download free" width="307" height="405" />
      </RightContainer>
    </StyledCentredContentBlock>
  )
}
