import styled from 'styled-components'
import { Container } from '@/components/shared/Container'
import { ThreeColumnFeatureBlockRecord } from 'types'
import { ThreeColumnFeatureCard } from './ThreeColumnFeatureCard'
import { Button } from '../shared/Button'

const StyledWrap = styled.section`
  padding: 120px 0;
  text-align: center;
  background-color: ${(p) => p.theme.v2.surface.white};

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding: 80px 0;
  }
`

const StyledHeading = styled.div`
  margin-bottom: 77px;

  h2 {
    font-size: ${(p) => p.theme.v2.typography.display.xxs.fontSize};
    line-height: ${(p) => p.theme.v2.typography.display.xxs.lineHeight};
    font-family: ${(p) => p.theme.v2.font.nantes};
    color: ${(props) => props.theme.v2.onSurface.text};
    margin: 8px 0 0;
  }

  p {
    font-family: ${(p) => p.theme.v2.font.lotaGrotesqueLight};
    color: ${(props) => props.theme.v2.onSurface.subduedText};
    margin: 0;
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin-bottom: 55px;
  }
`

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 32px;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.mobile}) {
    grid-template-columns: 1fr;
    gap: 47px;
  }
`

type ThreeColumnFeatureRecord = {
  content: ThreeColumnFeatureBlockRecord
}

export const ThreeColumnFeature = ({ content }: ThreeColumnFeatureRecord) => {
  const { preTitle, title, ctaText, ctaUrl } = content

  return (
    <StyledWrap className="three-column-feature">
      <Container>
        <StyledHeading>
          {preTitle && <p>{preTitle}</p>}
          {title && <h2>{title}</h2>}
        </StyledHeading>
        <StyledGrid>
          {content.cards.map((record) => (
            <ThreeColumnFeatureCard key={record.id} record={record} />
          ))}
        </StyledGrid>
        {ctaText && ctaUrl && <Button text={ctaText} url={ctaUrl} target="_blank" style="primary" />}
      </Container>
    </StyledWrap>
  )
}
