import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'

const StyledExpandableSection = styled.div`
  width: 100%;
  padding: 16px 0;
  border-bottom: 1px solid ${(props) => props.theme.v2.onSurface.subdued.border};
  border-left: none;
  border-right: none;
  font-size: 16px;
  line-height: 24px;
  font-family: ${(props) => props.theme.v2.font.lotaGrotesqueLight};
  position: relative;

  .contents {
    max-height: 0;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
    overflow: hidden;
  }

  &.active {
    .contents {
      max-height: 10000px;
      transition: max-height 1s ease-in-out;
    }
  }

  h3.section-header {
    font-family: ${(props) => props.theme.v2.font.lotaGrotesqueLight};
    font-size: 20px;
    line-height: 26px;
    width: 100%;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    margin: 0;

    @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  table {
    tbody {
      tr {
        td {
          font-size: 16px;
          line-height: 26px;
        }
      }
    }
  }
`

const StyledArrowImg = styled.img`
  width: 26px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 14px;
  }
`

// Use an invisible element to make sure that scrollIntoView brings the whole section below the menu bar
const StyledHiddenAnchor = styled.div`
  position: absolute;
  top: -100px;
`

export type ExpandableSectionBlockProps = {
  id: string
  title: string
  open?: boolean
  children: any
  onToggle: () => void
}

const isInViewport = (headerRef: HTMLDivElement, offset = 0) => {
  const top = headerRef.getBoundingClientRect().top
  return top + offset >= 0 && top - offset <= window.innerHeight
}

export const ExpandableSection = ({ onToggle, open, children, title, id }: ExpandableSectionBlockProps) => {
  const headerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (open && headerRef.current) {
      // Wait for the previously opened tab to close then scroll the newly opened one in view
      setTimeout(() => {
        if (headerRef.current && !isInViewport(headerRef.current)) {
          headerRef.current.scrollIntoView({ behavior: 'smooth' })
        }
      }, 500)
    }
  }, [open, headerRef.current])

  return (
    <StyledExpandableSection id={id} className={open ? 'active' : undefined}>
      <StyledHiddenAnchor ref={headerRef} />

      <h3 onClick={onToggle} className="section-header">
        <span>{title}</span>
        <StyledArrowImg src="/assets/img/icons/bounce-arrow.svg" alt="" width="40" height="20" />
      </h3>

      <div className="contents">{children}</div>
    </StyledExpandableSection>
  )
}
