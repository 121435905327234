import { LogoDropdownRecord, LogoGroupName } from 'types'
import { FeaturedInLogos } from './FeaturedInLogos'
import { JournalsLogos } from './JournalsLogos'
import { PartnerUniversitiesLogos } from './PartnerUniversitiesLogos'
import { ListenOnLogos } from './ListenOnLogos'
import { NatureMethodLogo } from './NatureMethodLogo'

const getLogoComponent = (logoGroupName: LogoGroupName) => {
  switch (logoGroupName) {
    case 'Journals':
      return <JournalsLogos />
    case 'Featured In':
      return <FeaturedInLogos />
    case 'Partner Universities':
      return <PartnerUniversitiesLogos />
    case 'Listen On':
      return <ListenOnLogos />
    case 'Nature Method':
      return <NatureMethodLogo />
    default:
      return null
  }
}

export const LogoDropdown = ({ record }: { record: LogoDropdownRecord }) => {
  return getLogoComponent(record.logoGroupName)
}
