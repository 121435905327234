import { ResponsiveImageType } from 'react-datocms'
import { DatoImage } from '@/elements/global/DatoImage'
import styled from 'styled-components'
import { ThreeColumnFeatureCardRecord } from 'types'

const StyledCard = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    flex-direction: column;
  }
`
const StyledImage = styled.div`
  position: relative;
  max-width: 266px;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    max-width: 160px;
  }
`

const StyledText = styled.div`
  position: relative;

  h3 {
    font-size: ${(p) => p.theme.v2.typography.body.l.fontSize};
    line-height: ${(p) => p.theme.v2.typography.body.l.lineHeight};
    font-family: ${(p) => p.theme.v2.font.nantes};
    color: ${(props) => props.theme.v2.onSurface.text};
    margin: 40px 0 0 0;
  }

  p {
    font-size: ${(p) => p.theme.v2.typography.body.s.fontSize};
    line-height: ${(p) => p.theme.v2.typography.body.s.lineHeight};
    font-family: ${(p) => p.theme.v2.font.lotaGrotesqueLight};
    color: ${(props) => props.theme.v2.onSurface.subduedText};
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.mobile}) {
    h3 {
      margin: 30px 0 0 0;
    }
  }
`

export const ThreeColumnFeatureCard = ({ record }: { record: ThreeColumnFeatureCardRecord }) => {
  return (
    <StyledCard>
      <StyledImage>
        <DatoImage data={record.image.responsiveImage} />
      </StyledImage>
      <StyledText>
        {record.link ? (
          <a href={record.link} target="_blank" rel="noreferrer">
            <h3>{record.title}</h3>
          </a>
        ) : (
          <h3>{record.title}</h3>
        )}
        <p>{record.description}</p>
      </StyledText>
    </StyledCard>
  )
}
