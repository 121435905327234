import React from 'react'
import styled from 'styled-components'

const StyledButton = styled.button`
  display: inline-flex;
  justify-content: center;
  padding: 16px 59px;
  border: 0;
  border-radius: 40px;
  font-size: ${(props) => props.theme.v2.typography.body.xs.fontSize};
  line-height: ${(props) => props.theme.v2.typography.body.xs.lineHeight};
  font-family: ${(props) => props.theme.v2.font.lotaGrotesqueRegular};

  &:hover {
    cursor: pointer;
  }

  &.primary {
    padding: 16px 59px;
    background-color: ${(props) => props.theme.v2.button.primary.bgColor};
    color: ${(props) => props.theme.v2.button.primary.textColor};

    &:hover {
      background-color: ${(props) => props.theme.v2.button.primary.hoverColor};
    }

    &:disabled {
      opacity: 0.5;
    }

    span.arrow-icon {
      background-image: url('/assets/img/icons/link-arrow-black.svg');
    }
  }

  &.secondary {
    padding: 16px 59px;
    background-color: ${(props) => props.theme.v2.button.secondary.bgColor};
    color: ${(props) => props.theme.v2.button.secondary.textColor};
    border-color: ${(props) => props.theme.v2.button.secondary.borderColor};

    &:hover {
      background-color: ${(props) => props.theme.v2.button.secondary.hoverColor};
      border-color: ${(props) => props.theme.v2.button.secondary.borderHoverColor};
    }

    &:disabled {
      background-color: ${(props) => props.theme.v2.button.secondary.disabledColor};
    }
  }

  &.dark {
    padding: 16px 59px;
    background-color: ${(props) => props.theme.v2.button.dark.bgColor};
    color: ${(props) => props.theme.v2.button.dark.textColor};

    &:hover {
      background-color: ${(props) => props.theme.v2.button.dark.hoverColor};
    }

    &:disabled {
      opacity: 0.5;
    }
  }

  &.bright {
    background-color: ${(props) => props.theme.v2.button.bright.bgColor};
    color: ${(props) => props.theme.v2.button.bright.textColor};

    &:hover {
      background-color: ${(props) => props.theme.v2.button.bright.hoverColor};
    }

    &:disabled {
      background-color: ${(props) => props.theme.v2.button.dark.disabledColor};
    }
  }

  &.bright-border {
    border: 1px solid ${(props) => props.theme.v2.button.brightBorder.borderColor};
    color: ${(props) => props.theme.v2.button.brightBorder.textColor};
    background-color: ${(props) => props.theme.v2.button.brightBorder.bgColor};

    &:hover {
      color: ${(props) => props.theme.v2.button.brightBorder.hoverColor};
      border: 1px solid ${(props) => props.theme.v2.button.brightBorder.hoverBorderColor};
    }

    span.arrow-icon {
      background-image: url('/assets/img/icons/button-arrow-blue.svg');
    }
  }

  &.small {
    padding: 10px 24px;
    font-size: ${(props) => props.theme.v2.typography.body.xxs.fontSize};
    line-height: ${(props) => props.theme.v2.typography.body.xxs.lineHeight};
  }

  &.full-width {
    width: 100%;
  }

  @keyframes bounceLeft {
    0%,
    25%,
    75%,
    100% {
      transform: translateX(0);
    }
    45% {
      transform: translateX(10px);
    }
    65% {
      transform: translateX(-5px);
    }
  }

  &:hover {
    span.arrow-icon {
      animation: bounceLeft 2s ease 1;
    }
  }
`

const StyledArrowImg = styled.span`
  float: right;
  width: 16px;
  height: 12px;
  margin-left: 10px;
  background-image: url('/assets/img/icons/link-arrow-white.svg');
  background-size: contain;
  background-repeat: no-repeat;
`

export interface StandardButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  btnStyle: 'primary' | 'secondary' | 'dark' | 'bright' | 'bright-border'
  size?: 'small' | 'full-width'
  testid?: string
  withArrow?: boolean
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
  children: any
}

export const StandardButton: React.FC<StandardButtonProps> = ({
  btnStyle,
  children,
  withArrow,
  size = 'small',
  ...props
}) => {
  return (
    <StyledButton {...props} className={`${btnStyle} ${size}`}>
      {children}
      {withArrow && <StyledArrowImg className="arrow-icon" />}
    </StyledButton>
  )
}
