import { StructuredText } from 'react-datocms'
import styled from 'styled-components'
import { CentredContentBlockRecord } from 'types'
import { ButtonBlock } from './ButtonBlock'
import { ExpandableSectionGroupBlock } from './ExpandableSectionGroupBlock'
import { IconBlock } from './Icon'
import { ListWithTitleBlock } from './ListWithTitleBlock'
import { LogoDropdown } from './LogoDropdown/LogoDropdown'
import { TrustpilotRatingBlock } from './TrustpilotRatingBlock'
import { theme } from 'styles/theme'
import { AlternateGrid } from './AlternateGrid'
import { TextWithIconBlock } from './TextWithIcon'
import { SciencePageLatestArticlesGroupBlock } from './SciencePageLatestArticlesGroupBlock'
import { SciencePageScientistsGroupBlock } from './SciencePageScientistsGroupBlock'
import { ThreeColumnFeature } from './ThreeColumnFeature'
import { HomePageFreeGuideBlock } from '@/blocks/HomePageFreeGuideBlock'
import { HomePageFreeGuideBlockAlt } from './HomePageFreeGuideBlockAlt'

const StyledContainer = styled.div`
  width: 100%;
  max-width: 980px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const StyledCentredContentBlock = styled.div<{ $bgColor: string; $bgColorMobile?: string; $fontColor?: string }>`
  background-color: ${(p) => p.$bgColor};
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    background-color: ${(p) => (p.$bgColorMobile ? p.$bgColorMobile : p.$bgColor)};
  }

  &.medium {
    padding: 80px 240px;

    @media screen and (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      padding: 64px 100px;
    }

    @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      padding: 48px 24px;
    }
  }

  &.large {
    height: 700px;

    @media screen and (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      padding: 0 100px;
    }

    @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      height: 100vh;
      padding: 0 24px;
    }
  }

  h1 {
    font-size: ${(props) => props.theme.v2.typography.display.s.fontSize};
    line-height: ${(props) => props.theme.v2.typography.display.s.lineHeight};
    font-family: ${(props) => props.theme.v2.font.nantes};
    color: ${(props) => props.theme.v2.surface.dark};
    text-align: center;
    margin: 0 0 24px;

    em {
      font-family: ${(p) => p.theme.v2.font.nantesItalic};
    }

    @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      font-size: ${(props) => props.theme.v2.typography.body.l.fontSize};
      line-height: ${(props) => props.theme.v2.typography.body.l.lineHeight};
      text-align: left;
    }
  }

  h2 {
    font-size: ${(props) => props.theme.v2.typography.body.l.fontSize};
    line-height: ${(props) => props.theme.v2.typography.body.l.lineHeight};
    font-family: ${(props) => props.theme.v2.font.lotaGrotesqueLight};
    color: ${(props) => props.theme.v2.onSurface.subduedText};
    margin: 0;
    padding: 0;
    text-align: center;

    @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      font-size: ${(props) => props.theme.v2.typography.body.s.fontSize};
      line-height: ${(props) => props.theme.v2.typography.body.s.lineHeight};
    }

    em {
      font-family: ${(p) => p.theme.v2.font.nantesItalic};
    }
  }

  h4 {
    font-size: ${(props) => props.theme.v2.typography.body.l.fontSize};
    line-height: ${(props) => props.theme.v2.typography.body.l.lineHeight};
    font-family: ${(props) => props.theme.v2.font.lotaGrotesqueLight};
    color: ${(props) => props.theme.v2.surface.dark};
    text-align: center;

    @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      font-size: ${(props) => props.theme.v2.typography.body.s.fontSize};
      line-height: ${(props) => props.theme.v2.typography.body.s.lineHeight};
    }
  }

  h5 {
    font-family: ${(props) => props.theme.v2.font.lotaGrotesqueLight};
    font-size: ${(props) => props.theme.v2.typography.body.m.fontSize};
    line-height: ${(props) => props.theme.v2.typography.body.m.lineHeight};
    text-align: center;

    @media screen and (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      font-size: ${(props) => props.theme.v2.typography.body.s.fontSize};
      line-height: ${(props) => props.theme.v2.typography.body.s.lineHeight};
    }
  }

  p {
    font-size: ${(props) => props.theme.v2.typography.display.xs.fontSize};
    line-height: ${(props) => props.theme.v2.typography.display.xs.lineHeight};
    font-family: ${(props) => props.theme.v2.font.nantes};
    color: ${(props) => (props.$fontColor ? props.$fontColor : theme.v2.surface.dark)};
    text-align: center;

    @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      font-size: ${(props) => props.theme.v2.typography.display.xxs.fontSize};
      line-height: ${(props) => props.theme.v2.typography.display.xxs.lineHeight};
    }

    @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      font-size: ${(props) => props.theme.v2.typography.body.xl.fontSize};
      line-height: ${(props) => props.theme.v2.typography.body.xl.lineHeight};
    }
  }

  img.icon {
    width: 48px;

    @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      width: 32px;
    }
  }

  .expandable-section-group {
    margin: 32px 0;

    h3 {
      font-size: ${(props) => props.theme.v2.typography.body.xl.fontSize};
      line-height: ${(props) => props.theme.v2.typography.body.xl.lineHeight};

      @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        font-size: ${(props) => props.theme.v2.typography.body.m.fontSize};
        line-height: ${(props) => props.theme.v2.typography.body.m.lineHeight};
      }
    }

    p {
      text-align: left;
      font-size: ${(props) => props.theme.v2.typography.body.m.fontSize};
      line-height: ${(props) => props.theme.v2.typography.body.m.lineHeight};

      @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        font-size: ${(props) => props.theme.v2.typography.body.xs.fontSize};
        line-height: ${(props) => props.theme.v2.typography.body.xs.lineHeight};
      }
    }
  }

  a {
    color: ${(props) => props.theme.v2.interactive.bright};

    &:hover {
      color: ${(props) => props.theme.v2.interactive.subdued};
    }
  }

  .button {
    margin-top: 55px;
  }

  &#ask-us-anything {
    h2 {
      font-size: ${(props) => props.theme.v2.typography.display.xs.fontSize};
      line-height: ${(props) => props.theme.v2.typography.display.xs.lineHeight};
      font-family: ${(props) => props.theme.v2.font.nantes};
      color: ${(props) => props.theme.v2.surface.dark};

      @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        font-size: ${(props) => props.theme.v2.typography.body.xl.fontSize};
        line-height: ${(props) => props.theme.v2.typography.body.xl.lineHeight};
      }
    }

    p {
      font-family: ${(props) => props.theme.v2.font.lotaGrotesqueLight};
    }
  }

  &#own-zoe-faqs {
    h4 {
      font-size: ${(props) => props.theme.v2.typography.body.l.fontSize};
      line-height: ${(props) => props.theme.v2.typography.body.l.lineHeight};
      font-family: ${(props) => props.theme.v2.font.lotaGrotesqueLight};
      color: ${(props) => props.theme.v2.onSurface.subduedText};
      text-align: center;
      margin: 0 0 32px;

      @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        font-size: ${(props) => props.theme.v2.typography.body.s.fontSize};
        line-height: ${(props) => props.theme.v2.typography.body.s.lineHeight};
        margin: 0 0 8px;
      }
    }

    h2 {
      font-size: ${(props) => props.theme.v2.typography.display.xs.fontSize};
      line-height: ${(props) => props.theme.v2.typography.display.xs.lineHeight};
      font-family: ${(props) => props.theme.v2.font.nantes};
      color: ${(props) => props.theme.v2.surface.dark};
      text-align: center;
      max-width: 464px;
      margin-top: 0;

      @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        font-size: ${(props) => props.theme.v2.typography.body.xl.fontSize};
        line-height: ${(props) => props.theme.v2.typography.body.xl.lineHeight};
      }
    }

    p {
      font-family: ${(props) => props.theme.v2.font.lotaGrotesqueLight};
    }
  }

  &#disclaimer {
    p {
      font-family: ${(props) => props.theme.v2.font.lotaGrotesqueLight};
      font-size: ${(props) => props.theme.v2.typography.body.s.fontSize};
      line-height: ${(props) => props.theme.v2.typography.body.s.lineHeight};
      color: ${(props) => props.theme.v2.onSurface.subduedText};
    }
  }

  &#results-reviews {
    padding: 40px 240px;
    p {
      font-family: ${(props) => props.theme.v2.font.lotaGrotesqueLight};
      font-size: ${(props) => props.theme.v2.typography.body.l.fontSize};
      line-height: ${(props) => props.theme.v2.typography.body.l.lineHeight};
    }

    @media screen and (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      padding: 64px 100px;
    }

    @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      padding: 32px 24px;
      margin-top: 1px;
      p {
        font-size: ${(props) => props.theme.v2.typography.body.xs.fontSize};
        line-height: ${(props) => props.theme.v2.typography.body.xs.lineHeight};
      }
    }
  }

  &#text-with-title {
    text-align: center;
    h1 {
      @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        align-self: start;
      }
    }
    @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      text-align: left;
    }
  }

  &#own-zoe-what-we-are-about {
    padding-right: 0;
    padding-left: 0;

    h3 {
      font-size: ${(props) => props.theme.v2.typography.display.xs.fontSize};
      line-height: ${(props) => props.theme.v2.typography.display.xs.lineHeight};
      font-family: ${(props) => props.theme.v2.font.nantes};
      color: ${(props) => props.theme.v2.surface.dark};
      text-align: center;
      max-width: 464px;
      margin-top: 0;
      margin-bottom: 77px;

      @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        margin-bottom: 30px;
      }
    }
  }

  &#get-started-now {
    ${StyledContainer} {
      h4 {
        padding: 0;
        margin: 0;
      }
      p {
        padding: 24px 8px;
        margin: 0;
      }
      a {
        margin-top: 32px;
      }
    }
  }

  &#free_guide_alt_section {
    ${StyledContainer} {
      max-width: 1200px;
    }

    &.medium {
      padding: 0 24px;
    }
  }

  &#quiz_cta {
    p {
      margin: 0;
      font-size: ${(props) => props.theme.v2.typography.body.l.fontSize};
      line-height: ${(props) => props.theme.v2.typography.body.l.lineHeight};
      font-family: ${(props) => props.theme.v2.font.lotaGrotesqueLight};
    }

    .button {
      margin-top: 32px;
    }
  }

  &#science-heading {
    h2 {
      margin: 0;
      font-size: 46px;
      line-height: 64px;
      font-family: ${(props) => props.theme.v2.font.nantes};

      em {
        font-family: ${(p) => p.theme.v2.font.nantesItalic};
      }
    }

    &.medium {
      padding-bottom: 0;
    }

    @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      h2 {
        font-size: ${(props) => props.theme.v2.typography.display.xs.fontSize};
        line-height: ${(props) => props.theme.v2.typography.display.xs.lineHeight};
      }
    }
  }

  &#trustpilot-rating {
    h2 {
      margin: 0 0 32px 0;
      font-size: 40px;
      line-height: 60px;
      font-family: ${(props) => props.theme.v2.font.nantes};
    }

    p {
      font-family: ${(props) => props.theme.v2.font.lotaGrotesqueLight};
      font-size: ${(props) => props.theme.v2.typography.body.xs.fontSize};
      line-height: ${(props) => props.theme.v2.typography.body.xs.lineHeight};
    }

    padding: 48px 24px;
    margin-bottom: 80px;

    @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      h2 {
        font-size: 32px;
        line-height: 46px;
        text-align: left;
      }
      margin-bottom: 32px;
    }
  }

  &#method-paper {
    h3 {
      margin: 0 0 16px 0;
    }
  }
`

const renderBlock = (record, currentPath?: string, customId?: string) => {
  const trackingType = currentPath === '/ownzoe' ? 'Own Zoe' : 'Homepage'

  switch (record.__typename) {
    case 'LogoDropdownRecord':
      return <LogoDropdown record={record} />
    case 'ExpandableSectionGroupRecord':
      return <ExpandableSectionGroupBlock record={record} trackingOptions={{ type: trackingType }} />
    case 'IconRecord':
      return <IconBlock record={record} />
    case 'ButtonRecord':
      return <ButtonBlock record={record} />
    case 'TrustpilotRatingRecord':
      return <TrustpilotRatingBlock record={record} />
    case 'ListWithTitleRecord':
      return <ListWithTitleBlock record={record} />
    case 'AlternateGridRecord':
      return <AlternateGrid content={record} />
    case 'TextWithIconRecord':
      return <TextWithIconBlock record={record} />
    case 'SciencePageLatestArticlesGroupRecord':
      return <SciencePageLatestArticlesGroupBlock record={record} />
    case 'SciencePageScientistsGroupRecord':
      return <SciencePageScientistsGroupBlock record={record} />
    case 'ThreeColumnFeatureRecord':
      return <ThreeColumnFeature content={record} />
    case 'FreeGuideBlockRecord':
      if (customId === 'free_guide_alt_section') {
        return <HomePageFreeGuideBlockAlt record={record} key={record.id} />
      } else {
        return <HomePageFreeGuideBlock record={record} key={record.id} />
      }
    default:
      return null
  }
}

export const CentredContentBlock = ({
  record,
  currentPath,
}: {
  record: CentredContentBlockRecord
  currentPath?: string
}) => {
  const customId = record.customId
  return (
    <StyledCentredContentBlock
      id={record.customId}
      $bgColor={record.bgColor}
      $bgColorMobile={record.bgColorMobile}
      $fontColor={record.fontColor}
      className={record.height}
    >
      <StyledContainer>
        <StructuredText
          data={record.content}
          renderBlock={({ record }) => renderBlock(record, currentPath, customId)}
        />
      </StyledContainer>
    </StyledCentredContentBlock>
  )
}
