import styled from 'styled-components'
import { AlternateGridBlockRecord } from 'types'
import { AlternateGridRow } from './AlternateGridRow'

const StyledWrap = styled.section`
  text-align: center;
`

type AlternateGridRecord = {
  content: AlternateGridBlockRecord
}

export const AlternateGrid = ({ content }: AlternateGridRecord) => {
  return (
    <StyledWrap>
      {content.content.map((row, idx) => {
        const isEven = idx % 2 === 0
        return <AlternateGridRow record={row} key={row.id} rowAlt={isEven} />
      })}
    </StyledWrap>
  )
}
