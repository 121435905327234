import React from 'react'
import styled from 'styled-components'
import { TableRecord } from 'types'
import { Container } from '@/components/shared/Container'

const StyledTable = styled.table`
  margin-top: 50px;
  margin-bottom: 56px;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
  td,
  th {
    padding: 16px 10px 16px 0;
  }
  td:last-child,
  th:last-child {
    padding-right: 0;
  }
  tr {
    border-bottom: 1px solid ${(props) => props.theme.colours.tableBorder};
  }
  tbody tr:last-child {
    border-bottom: none;
  }
  th {
    font-size: 16px;
    line-height: 24px;
    color: ${(props) => props.theme.colours.secondary};
    font-weight: 300;
    text-transform: uppercase;
  }
  td {
    font-size: 20px;
    line-height: 34px;
    color: ${(props) => props.theme.colours.primary};
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    th {
      font-size: 14px;
      line-height: 20px;
    }
    td {
      font-size: 16px;
      line-height: 24px;
    }
  }
`

export const Table = ({ record }: { record: TableRecord }) => {
  return (
    <Container $size="narrow">
      <StyledTable>
        <thead>
          <tr>
            {record.table.columns.map((header, hIndex) => (
              <th key={hIndex}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {record.table.data.map((row, rIndex) => (
            <tr key={rIndex}>
              {record.table.columns.map((column, cIndex) => (
                <td key={cIndex}>{row[column] || ''}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </StyledTable>
    </Container>
  )
}
