import styled from 'styled-components'
import { ListWithTitleRecord } from 'types'

const StyledList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 6px 0;
  padding: 0;
  width: 100%;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin-top: 12px;
  }

  .list-title {
    font-size: ${(props) => props.theme.v2.typography.body.m.fontSize};
    line-height: ${(props) => props.theme.v2.typography.body.m.lineHeight};
    margin: 0 auto;

    @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      font-size: ${(props) => props.theme.v2.typography.body.xs.fontSize};
      line-height: ${(props) => props.theme.v2.typography.body.xs.lineHeight};
    }
  }

  .separator {
    margin: 32px auto;
  }

  .list-items {
    margin: 0 auto;
  }
`

const StyledListItem = styled.li`
  display: flex;
  font-family: ${(props) => props.theme.v2.font.lotaGrotesqueLight};
  font-size: ${(props) => props.theme.v2.typography.body.l.fontSize};
  line-height: ${(props) => props.theme.v2.typography.body.l.lineHeight};

  :not(:last-child) {
    margin-bottom: 16px;
  }

  .list-marker {
    margin-right: 16px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.v2.typography.body.s.fontSize};
    line-height: ${(props) => props.theme.v2.typography.body.s.lineHeight};
  }
  
  &#image-list {
    @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
      font-size: ${(props) => props.theme.v2.typography.body.m.fontSize};
      line-height: ${(props) => props.theme.v2.typography.body.m.lineHeight};
    }  
`

export const ListWithTitleBlock = ({ record, customId }: { record: ListWithTitleRecord; customId?: string }) => {
  return (
    <StyledList>
      {record.listTitle && <p className="list-title">{record.listTitle}</p>}

      {record.separator && <img src={record.separator.url} alt="" className="separator" />}
      <div className="list-items">
        {record.list.map((listItem) => (
          <StyledListItem key={listItem.id} id={customId}>
            <img src={listItem.listMarkerIcon.url} alt="" className="list-marker" />
            <span>{listItem.text}</span>
          </StyledListItem>
        ))}
      </div>
    </StyledList>
  )
}
