import styled from 'styled-components'
import { SciencePageScientistRecord } from 'types'
import { StructuredText } from 'react-datocms'

const StyledScientistBlock = styled.div`
  &.science-page-scientist {
    background-color: ${(props) => props.theme.v2.surface.cream500};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 16px;
    min-width: 270px;
    max-width: 400px;

    h4 {
      font-size: ${(p) => p.theme.v2.typography.body.m.fontSize};
      line-height: ${(p) => p.theme.v2.typography.body.m.lineHeight};
      font-family: ${(p) => p.theme.v2.font.nantes};
      color: ${(props) => props.theme.v2.onSurface.text};
      margin-bottom: 16px;
      margin-top: 10px;
      text-align: left;
    }

    p {
      font-size: ${(p) => p.theme.v2.typography.body.xs.fontSize};
      line-height: ${(p) => p.theme.v2.typography.body.xs.lineHeight};
      font-family: ${(p) => p.theme.v2.font.lotaGrotesqueLight};
      color: ${(props) => props.theme.v2.onSurface.text};
      text-align: left;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  a:hover * {
    color: ${(props) => props.theme.v2.interactive.bright};
  }
`

const StyledImageContainer = styled.div`
  background-color: #f8eabd;
  border-radius: 8px;
  display: flex;
  justify-content: center;
`

type SciencePageScientistBlockProps = {
  record: SciencePageScientistRecord
}

export const SciencePageScientistBlock: React.FC<SciencePageScientistBlockProps> = ({ record }) => {
  return (
    <StyledScientistBlock className="science-page-scientist">
      <StyledImageContainer>
        <img src={record.profileImage.responsiveImage.src} alt="Scientist profile" />
      </StyledImageContainer>

      <a href={record.link} target="_blank" rel="noreferrer">
        <StructuredText data={record.name} />
      </a>

      <StructuredText data={record.description} />
    </StyledScientistBlock>
  )
}
