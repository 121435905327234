import React from 'react'
import { StructuredText } from 'react-datocms'
import { HomePageFreeGuideBlockRecord } from 'types'
import { Button } from '@/components/shared/Button'
import styled from 'styled-components'
import { trackCtaClick } from '@/lib/gtm/gtmV2'

type HomePageFreeGuideBlockAltProps = {
  record: HomePageFreeGuideBlockRecord
}

const StyledCentredContentBlock = styled.div`
  padding: 40px 0;
  width: 100%;
  color: ${(p) => p.theme.v2.surface.white};
  background-color: ${(p) => p.theme.v2.surface.dark};
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 160px;
  margin: 0 auto 32px;
  border-radius: 30px;

  .free-guide-container-left h2 {
    font-size: ${(p) => p.theme.v2.typography.display.xs.fontSize};
    line-height: ${(p) => p.theme.v2.typography.display.xs.lineHeight};
    font-family: ${(p) => p.theme.v2.font.nantes};
    text-align: left;
    color: ${(p) => p.theme.v2.surface.white};

    @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: ${(p) => p.theme.v2.typography.body.xl.fontSize};
      line-height: ${(p) => p.theme.v2.typography.body.xl.lineHeight};
      text-align: center;
    }
  }

  .free-guide-container-left h6 {
    font-family: ${(p) => p.theme.v2.font.lotaGrotesqueLight};
    font-size: ${(p) => p.theme.v2.typography.body.xs.fontSize};
    line-height: ${(p) => p.theme.v2.typography.body.xs.lineHeight};
    margin-block-start: 16px;
    margin-block-end: 16px;

    @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
      text-align: center;
    }
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.largeDesktop}) {
    gap: 80px;
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    gap: 32px;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.mobile}) {
    flex-direction: column-reverse;
    padding: 32px 16px;
    margin-top: 40px;
    border-radius: 20px;
  }
`

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 350px;

  a.button {
    margin-top: 0px;
  }
`

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  min-width: 170px;

  img {
    border-radius: 16px;
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    align-items: center;
    img {
      max-width: 144px;
    }
  }
`

export const HomePageFreeGuideBlockAlt: React.FC<HomePageFreeGuideBlockAltProps> = ({ record }) => {
  return (
    <StyledCentredContentBlock className="free-guide-container">
      <LeftContainer className="free-guide-container-left">
        <StructuredText data={record.header} />
        <StructuredText data={record.subheader} />

        <Button
          key={record.id}
          url="/gutguide"
          text={record.cta}
          style="primary"
          onClick={() => {
            trackCtaClick('homepage', record.cta)
          }}
        />
      </LeftContainer>
      <RightContainer>
        <img src="/assets/img/gutshot/gut-health.png" alt="Download free" width="307" height="405" />
      </RightContainer>
    </StyledCentredContentBlock>
  )
}
