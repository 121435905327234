import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import { ExpandableSectionRecord } from 'types'
import { ExpandableSectionBlock } from './ExpandableSectionBlock'
import { trackAccordionClickEvent } from '@/lib/gtm/gtmV2'

const StyledExpandableSectionGroup = styled.div`
  width: 100%;
`

type TrackingOptions = {
  type: string
}

type ExpandableSectionGroupRecord = {
  record: {
    sections: {
      blocks: ExpandableSectionRecord[]
    }
  }
  trackingOptions: TrackingOptions
}

export const ExpandableSectionGroupBlock = ({ record, trackingOptions }: ExpandableSectionGroupRecord) => {
  const [expandedSectionId, setExpandendedSectionId] = useState<string | undefined>()

  const onToggleOpen = useCallback(
    (selectedSection: ExpandableSectionRecord) => {
      if (selectedSection.id === expandedSectionId) {
        setExpandendedSectionId(undefined)
      } else {
        setExpandendedSectionId(selectedSection.id)
        trackAccordionClickEvent({
          section: 'cms',
          textOrImageUrl: selectedSection.title,
        })
      }
    },
    [expandedSectionId],
  )

  return (
    <StyledExpandableSectionGroup className="expandable-section-group">
      {record.sections.blocks.map((expandableSection) => (
        <ExpandableSectionBlock
          id={expandableSection.id}
          onToggle={() => onToggleOpen(expandableSection)}
          open={expandedSectionId === expandableSection.id}
          record={expandableSection}
          key={expandableSection.id}
        />
      ))}
    </StyledExpandableSectionGroup>
  )
}
