import Link from 'next/link'
import styled from 'styled-components'
import { TrustpilotRatingRecord } from 'types'
import { TrustpilotStarRating } from '../shared/TrustpilotStarRating'
import { StructuredText } from 'react-datocms'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    text-align: left;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
  }
`

type TrustpilotRatingBlockProps = {
  record: TrustpilotRatingRecord
}

export const TrustpilotRatingBlock: React.FC<TrustpilotRatingBlockProps> = ({ record }) => {
  return (
    <StyledContainer>
      <TrustpilotStarRating rating={parseFloat(process.env.TRUSTPILOT_RATING_SCORE || '4.3')}>
        {!record.tagline && (
          <>
            <strong>{process.env.TRUSTPILOT_RATING_TEXT}</strong> on Trustpilot{' '}
            <Link href="https://uk.trustpilot.com/review/joinzoe.com" target="_blank">
              Read reviews
            </Link>
          </>
        )}
      </TrustpilotStarRating>
      {record.tagline && <StructuredText data={record.tagline} />}
    </StyledContainer>
  )
}
