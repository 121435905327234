import styled from 'styled-components'
import { SciencePageLatestArticlesGroupRecord } from 'types'
import { SciencePageLatestArticleBlock } from '@/components/blocks/SciencePageLatestArticleBlock'

const StyledStudiesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 47px;
  margin-bottom: 46px;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    grid-template-columns: 1fr;
  }
`

type SciencePageLatestArticlesGroupBlockProps = {
  record: SciencePageLatestArticlesGroupRecord
}

export const SciencePageLatestArticlesGroupBlock: React.FC<SciencePageLatestArticlesGroupBlockProps> = ({ record }) => {
  return (
    <StyledStudiesContainer>
      {record.articles.slice(0, 3).map((study) => (
        <SciencePageLatestArticleBlock record={study} key={study.id} />
      ))}
    </StyledStudiesContainer>
  )
}
