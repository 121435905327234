import styled from 'styled-components'

export const CONTAINER_WIDTH_NARROW = 684
const CONTAINER_WIDTH_WIDE = 1298
const CONTAINER_WIDTH_MED = 1136
const CONTAINER_WIDTH_SMALL = 1008 // 960px + (24px * 2) for horizontal padding

export const Container = styled.div<{
  $size?: string
  $padding?: string
  $flex?: boolean
  $align?: string
  $direction?: string
  $height?: string
}>`
  width: 100%;
  max-width: 1008px;
  margin: 0 auto;
  padding: 0 24px;
  ${(props) => props.$size === 'wide' && `max-width: ${CONTAINER_WIDTH_WIDE}px;`}
  ${(props) => props.$size === 'med' && `max-width: ${CONTAINER_WIDTH_MED}px;`}
  ${(props) => props.$size === 'small' && `max-width: ${CONTAINER_WIDTH_SMALL}px;`}
  ${(props) => props.$size === 'narrow' && `max-width: ${CONTAINER_WIDTH_NARROW}px;`}
  ${(props) => props.$flex === true && `display:flex;`}
  ${(props) => props.$align === 'center' && `align-items: center;`}
  ${(props) => props.$align === 'top' && `align-items: flex-start;`}
  ${(props) => props.$direction && `flex-direction: ${props.$direction};`}
  @media screen and (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding: 0 20px;
    ${(props) => props.$padding === 'no-mobile' && `padding: 0;`}
    ${(props) => props.$flex === true && `flex-direction:column;`}
  }
  ${(props) => !!props.$height && `height: ${props.$height};`}
`
