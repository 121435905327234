/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import { Image } from 'react-datocms'

type ImgElementStyle = NonNullable<JSX.IntrinsicElements['img']['style']>

type ImageWithAmpProps = {
  data: any
  className?: string
  layout?: 'fixed' | 'responsive' | 'intrinsic' | 'fill'
  lazyLoad?: boolean
  objectFit?: ImgElementStyle['objectFit']
  objectPosition?: ImgElementStyle['objectPosition']
}

export const DatoImage = ({ data, className, layout, lazyLoad, objectFit, objectPosition }: ImageWithAmpProps) => {
  return (
    <Image
      data={data}
      className={className}
      layout={layout}
      lazyLoad={lazyLoad ?? true}
      objectFit={objectFit}
      objectPosition={objectPosition}
    />
  )
}
