import styled from 'styled-components'

const StyledPartnerUniversitiesLogos = styled.ul`
  width: 100%;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    flex-wrap: wrap;
  }
`

const StyledPartnerUniversity = styled.li<{ $bgColor: string }>`
  background-color: ${(props) => props.$bgColor};
  padding: 0 16px;
  font-family: ${(props) => props.theme.v2.font.nantes};
  width: 33.33%;
  height: 25vw;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 50%;
    height: 50vw;

    &:nth-last-child(1) {
      width: 100%;
    }
  }

  div {
    padding-bottom: 8px;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    @media screen and (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      padding-bottom: 8px;
    }
  }

  p {
    font-size: ${(props) => props.theme.v2.typography.body.l.fontSize};
    line-height: ${(props) => props.theme.v2.typography.body.l.lineHeight};
    margin: 0;
    padding-top: 8px;
    height: 50%;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: flex-start;

    @media screen and (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      font-size: ${(props) => props.theme.v2.typography.body.s.fontSize};
      line-height: ${(props) => props.theme.v2.typography.body.s.lineHeight};
      padding-top: 8px;
    }
  }

  img {
    height: 30px;
  }
`

const universities = [
  {
    name: 'Stanford Medicine',
    bgColor: '#C3D2A8',
  },
  {
    name: 'Massachusetts General Hospital',
    bgColor: '#F4B4B5',
  },
  {
    name: 'King’s College London',
    bgColor: '#F7AD77',
  },
]

export const PartnerUniversitiesLogos = () => {
  return (
    <StyledPartnerUniversitiesLogos>
      {universities.map((uni) => (
        <StyledPartnerUniversity $bgColor={uni.bgColor} key={uni.name}>
          <div>
            <img src="/assets/img/logos/university-icon.svg" alt="" width="25" height="25" />
          </div>
          <p>{uni.name}</p>
        </StyledPartnerUniversity>
      ))}
    </StyledPartnerUniversitiesLogos>
  )
}
